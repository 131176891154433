// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';

// local imports
//
import TextInput from '../../components/TextInput';

/**
 * The component property types
 */
interface Props {
    hasBlurred?: boolean;
    error?:      string;
    value:       string;
    onBlur:      (field: string) => void;
    onChange:    (field: string, value: string) => void;
};

/**
 * The Business Address second line component
 */
const BusinessAddress2: FunctionComponent<Props> = (props: Props): JSX.Element => (
    <TextInput
        id="address2"
        field="address2"
        hasBlurred={props.hasBlurred}
        error={props.error}
        value={props.value}
        placeholder="eg. Suite 101"
        onBlur={props.onBlur}
        onChange={props.onChange}
    />
);

// export the component
//
export default BusinessAddress2;
