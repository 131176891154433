// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';

// local imports
//
import PhoneInput from '../../components/PhoneInput';

/**
 * The component properties
 */
interface Props {
    required?: boolean;
    helpText?: string;
    hasBlurred?: boolean;
    error?: string;
    value: string;
    onBlur:      (field: string) => void;
    onChange:    (field: string, value: string) => void;
};

/**
 * The BusinessPhone component
 */
const BusinessPhone: FunctionComponent<Props> = (props: Props): JSX.Element => (
    <PhoneInput
        required
        id="businessPhone"
        field="businessPhone"
        label="Business Phone"
        helpText={props.helpText}
        hasBlurred={props.hasBlurred}
        error={props.error}
        value={props.value}
        placeholder="eg. 555 555 5555"
        onBlur={props.onBlur}
        onChange={props.onChange}
    />
);

// export the component
//
export default BusinessPhone;
