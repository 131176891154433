// mpm imports
//
import debug from 'debug';

// local imports
//
import {
    GOOGLE_SEARCH,
    ADDRESS_1,
    ADDRESS_2,
    BUSINESS_NAME,
    BUSINESS_PHONE,
    CITY,
    EMAIL,
    CONTACT_NAME,
    PHONE,
    STATE,
    COUNTRY,
    ZIP_CODE,
    SUBMIT_BUTTON
} from './constants/form-fields';
import { FormType } from '../App.d';

// initialize the logging function
//
const log = debug('getlisted:parse-location');

/**
 * The return value for the parse function
 */
interface ParsedUrl {
    form:     string[];
    formType: FormType;
};

/**
 * Parse a URL path to extract the country and the form elements
 * @param  {string} path The path to parse
 * @return {ParsedUrl}   The parsed results
 */
export const parse = (path: string = '/'): ParsedUrl => {
    log('parsing URL path to get form details');
    // divide the path into segments, filtering out any empty segments and
    // converting to lower case
    //
    const formSegment =  (path.toLowerCase().split('/').filter((seg) => !!seg) || ['gso'])[0];
    log('parsed forms from path: %s', formSegment);

    let formType: FormType = 'gso';
    log('got form type: %s', formType);

    // declare a variable to hold the list of form elements
    //
    let form: string[];

    // check the segment of the URL describing the form
    //
    switch(formSegment) {
        // full sales lead - include everything except the Google search element
        //
        case 'fsl':
            formType = 'fsl';
            form = [
                ADDRESS_1,
                ADDRESS_2,
                BUSINESS_NAME,
                BUSINESS_PHONE,
                CITY,
                EMAIL,
                CONTACT_NAME,
                PHONE,
                STATE,
                COUNTRY,
                ZIP_CODE,
                SUBMIT_BUTTON,
            ];
            break;

        // business details only - include all the business info fields and no
        // Google search
        //
        case 'bdo':
            formType = 'bdo';
            form = [
                ADDRESS_1,
                ADDRESS_2,
                CITY,
                BUSINESS_NAME,
                BUSINESS_PHONE,
                PHONE,
                STATE,
                COUNTRY,
                ZIP_CODE,
                SUBMIT_BUTTON,
            ];
            break;

        // google search only
        //
        case 'gso':
            formType = 'gso';
            form = [GOOGLE_SEARCH];
            break;

        // default: google sales lead - google search plus the sales lead fields
        //
        default:
            formType = 'gsl';
            form = [GOOGLE_SEARCH, CONTACT_NAME, EMAIL, PHONE];
            break;
    }

    log('checked form segments: formType=%s, form %O', formType, form);

    // return the results
    //
    return { form, formType };
};
