// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';
import debug from 'debug';

// local imports
//
import '../../assets/css/FSL.scss';
import Columns from '../components/Columns';
import Column from '../components/Column';
import TextInput from '../components/TextInput';
import PhoneInput from '../components/PhoneInput';
import StateSelect from '../components/StateSelect';
import CountrySelect from '../components/CountrySelect';
import ZipCodeInput from '../components/ZipCodeInput';
import BusinessName from './fields/BusinessName';
import BusinessPhone from './fields/BusinessPhone';
import BusinessAddress1 from './fields/BusinessAddress1';
import BusinessAddress2 from './fields/BusinessAddress2';
import { CountryType } from '../../App.d';

// initialize the log function
//
const log = debug('getlisted:FSL');

/**
 * The component property types
 */
interface Props {
    nameHasBlurred?:          boolean;
    emailHasBlurred?:         boolean;
    businessNameHasBlurred?:  boolean;
    businessPhoneHasBlurred?: boolean;
    phoneHasBlurred?:         boolean;
    address1HasBlurred?:      boolean;
    address2HasBlurred?:      boolean;
    cityHasBlurred?:          boolean;
    stateHasBlurred?:         boolean;
    zipHasBlurred?:           boolean;
    nameError?:               string;
    emailError?:              string;
    businessNameError?:       string;
    businessPhoneError?:      string;
    phoneError?:              string;
    address1Error?:           string;
    address2Error?:           string;
    cityError?:               string;
    stateError?:              string;
    zipError?:                string;
    name:                     string;
    email:                    string;
    businessName:             string;
    businessPhone:            string;
    phone:                    string;
    address1:                 string;
    address2?:                string;
    city:                     string;
    state:                    string;
    country:                  CountryType;
    zip:                      string;
    onBlur:                   (field: string) => void;
    onChange:                 (field: string, value: string) => Promise<any>;
    onClickScan:              () => void;
};

/**
 * Determine if the form is valid
 * @param  props The component properties
 * @return `true` if the form is valid; `false` otherwise
 */
const validate = (props: Props): boolean => !props.nameError
    && !props.emailError
    && !props.phoneError
    && !props.businessNameError
    && !props.businessPhoneError
    && !props.address1Error
    && !props.address2Error
    && !props.cityError
    && !props.stateError
    && !props.zipError;

/**
 * the FSL (Full Sales Lead) form
 * @return The React component
 */
const FSL: FunctionComponent<Props> = (props: Props): JSX.Element => {
    // determine if the form is valid
    //
    const isValid = validate(props);
    log('rendering FSL: isValid = %s', isValid ? 'true' : 'false');

    // set the help text for the address input elements
    //
    const addressHelpText = 'We use this information to scan the web for your' +
        ' business listings and check their accuracy';

    // render & return the component
    //
    return (
        <div className="fsl form">
            <Columns className="fsl">
                <Column standard>

                    <Columns className="sales-lead is-hidden">

                        <Column width="one-third">
                            <TextInput
                                id="name"
                                field="name"
                                label="Name"
                                required
                                hasBlurred={props.nameHasBlurred}
                                error={props.nameError}
                                value={props.name}
                                placeholder="eg. John Smith"
                                onBlur={props.onBlur}
                                onChange={props.onChange}
                            />
                        </Column>

                        <Column width="one-third">
                            <TextInput
                                id="email"
                                field="email"
                                label="Email"
                                helpText={
                                    `We'd like to send you a copy of your ` +
                                    'scan and additional information about our products'
                                }
                                required
                                hasBlurred={props.emailHasBlurred}
                                error={props.emailError}
                                value={props.email}
                                placeholder="email"
                                onBlur={props.onBlur}
                                onChange={props.onChange}
                            />
                        </Column>

                        <Column width="one-third">
                            <PhoneInput
                                id="phone"
                                field="phone"
                                label="Phone"
                                helpText="We ask for this so we can call you & discuss our products"
                                required
                                hasBlurred={props.phoneHasBlurred}
                                error={props.phoneError}
                                value={props.phone}
                                placeholder="phone"
                                onBlur={props.onBlur}
                                onChange={props.onChange}
                            />
                        </Column>

                    </Columns>

                    <Columns className="business-name-phone">

                        <Column width="9">
                            <BusinessName
                                required
                                hasBlurred={props.businessNameHasBlurred}
                                error={props.businessNameError}
                                value={props.businessName}
                                helpText={addressHelpText}
                                onBlur={props.onBlur}
                                onChange={props.onChange}
                            />
                        </Column>

                        <Column>
                            <BusinessPhone
                                required
                                helpText={addressHelpText}
                                hasBlurred={props.businessPhoneHasBlurred}
                                error={props.businessPhoneError}
                                value={props.businessPhone}
                                onBlur={props.onBlur}
                                onChange={props.onChange}
                            />
                        </Column>

                    </Columns>

                    <BusinessAddress1
                        required
                        hasBlurred={props.address1HasBlurred}
                        error={props.address1Error}
                        helpText={addressHelpText}
                        value={props.address1}
                        onBlur={props.onBlur}
                        onChange={props.onChange}
                    />


                    <BusinessAddress2
                        hasBlurred={props.address2HasBlurred}
                        error={props.address2Error}
                        value={props.address2 || ''}
                        onBlur={props.onBlur}
                        onChange={props.onChange}
                    />


                    <Columns>
                        <Column width="5">
                            <TextInput
                                required
                                id="city"
                                field="city"
                                label="City"
                                helpText={addressHelpText}
                                hasBlurred={props.cityHasBlurred}
                                error={props.cityError}
                                value={props.city}
                                placeholder="eg. Los Angeles"
                                onBlur={props.onBlur}
                                onChange={props.onChange}
                            />
                        </Column>


                        <Column width="3">
                            <StateSelect
                                required
                                country={props.country}
                                id="state"
                                field="state"
                                label={props.country === 'us'
                                    ? 'State'
                                    : 'Province'
                                }
                                caption={props.country === 'us'
                                    ? 'Please provide the state'
                                    : 'Please provide the province'
                                }
                                helpText={addressHelpText}
                                hasBlurred={props.stateHasBlurred}
                                error={props.stateError}
                                value={props.state}
                                onBlur={props.onBlur}
                                onChange={props.onChange}
                            />
                        </Column>


                        <Column width="2">
                            <CountrySelect
                                id="country"
                                field="country"
                                label="Country"
                                helpText={addressHelpText}
                                value={props.country}
                                onChange={props.onChange}
                            />
                        </Column>


                        <Column width="2">
                            <ZipCodeInput
                                required
                                country={props.country}
                                id="zip"
                                field="zip"
                                label={props.country === 'us'
                                    ? 'Zip Code'
                                    : 'Postal Code'
                                }
                                helpText={addressHelpText}
                                hasBlurred={props.zipHasBlurred}
                                error={props.zipError}
                                value={props.zip}
                                placeholder={props.country === 'us'
                                    ? 'eg. 90210'
                                    : 'eg. A0A 0A0'
                                }
                                onBlur={props.onBlur}
                                onChange={props.onChange}
                            />
                        </Column>

                    </Columns>

                    <button
                        disabled={!isValid}
                        className="button continue-button is-success"
                        onClick={props.onClickScan}
                    >
                        Take me to my scan!
                    </button>

                </Column>
            </Columns>
        </div>
    );
};

// export the component
//
export default FSL;
