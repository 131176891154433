import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// npm imports
//
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { setAppElement } from 'react-modal';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import debug from 'debug';

// local imports
//
import './assets/css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { parse } from './modules/parse-location';

// initialize the log function
//
const log = debug('getlisted:index');

// initialize the app history object
//
log('creating browser history');
const history = createBrowserHistory();

// initialize the app element for the react-modal component
//
log('setting app element for react-modal');
setAppElement('#root');

// render the App component
//
log('rendering application');
ReactDOM.render(
    (
        <Router history={history}>
            <App history={history} {...parse(window.location.pathname)} />
        </Router>
    ),
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
