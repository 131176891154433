// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Cleave from 'cleave.js/react';
import debug from 'debug';

// local imports
//
import '../../assets/css/TextInput.scss';
import Caption from './Caption';

// initialize the log function
//
const log = debug('getlisted:PhoneInput');

/**
 * The event object type
 */
interface Event {
    target: { value: string };
};

/**
 * The component properties type
 */
interface Props {
    id?:          string;
    field:        string;
    label?:       string;
    caption?:     string;
    helpText?:    string;
    required?:    boolean;
    hasBlurred?:  boolean;
    error?:       string;
    value:        string;
    placeholder?: string;
    onBlur:       (field: string) => void;
    onChange:     (field: string, value: string) => void;
};

interface CleaveElementProps {
    id?: string;
    className: string;
    placeholder: string;
    options: {
        phone: boolean;
        phoneRegionCode: 'US';
    };
    value: string;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * The PhoneInput component
 * @return The React component
 */
const PhoneInput: FunctionComponent<Props> = (props: Props): JSX.Element => {
    log('rendering PhoneInput component');
    // build the properties for the input elements
    //
    const inputProps: CleaveElementProps = {
        className:   classnames('input', { 'is-danger': props.hasBlurred && props.error }),
        placeholder: props.placeholder || '',
        options:     { phone: true, phoneRegionCode: 'US' },
        value:       props.value,
        onBlur:      () => props.onBlur(props.field),
        onChange:    (ev: Event) => props.onChange(props.field, ev.target.value),
    };

    // do we have an ID value? if so, add it to the input and error elements
    //
    if (props.id) {
        inputProps.id = `${props.id}-input`;
    }
    log('input element props = %O', inputProps);

    // render a label element if a label was provided
    //
    const labelElement = props.label ? <label className="label">{props.label}</label> : null;

    // render & return the component
    //
    return (
        <div className="field TextInput">
            {labelElement}
            {props.required ? <span className="required-asterisk">*</span> : null}
            {props.helpText
                ? (
                    <span
                        id={`${props.field}-help-icon`}
                        className="jam jam-help"
                        data-for="react-tooltip"
                        data-tip="custom show"
                        data-event="click focus"
                    />
                )
                : null
            }
            {props.helpText
                ? (
                    <ReactTooltip
                        id="react-tooltip"
                        type="light"
                        globalEventOff="click"
                    >
                        {props.helpText}
                    </ReactTooltip>
                )
                : null
            }
            <div className="control">
                <Cleave {...inputProps} />
            </div>
            <Caption
                id={props.id}
                caption={props.caption}
                hasBlurred={props.hasBlurred}
                error={props.error}
            />
        </div>
    );
};

// export the component
//
export default PhoneInput;
