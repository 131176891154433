// npm imports
//
import React, { FunctionComponent } from 'react';

// local imports
//
import '../../assets/css/ScanResultsMessage.scss';

interface Props {
    onClick: (event: any) => Promise<void>;
};

const ScanResultsMessage: FunctionComponent<Props> = ({ onClick }) => (
    <div className="ScanResultsMessage">

        <div className="message-section listings-message">
            <p className="title">Why Listings Are Important For Your Business</p>
            <p>Accurate listings make you DISCOVERABLE.</p>
            <p>You may not know all the sites by name but Google does.</p>
            <p>Your profile on these sites helps determine your RANKING!</p>
            <p>RDM makes sure your information is CONSISTENT where it matters.</p>
        </div>

        <div className="message-section call-to-action">
            <h1 className="has-text-centered">Want to see how discoverable you are?</h1>

            <button
                className="button full-report is-success is-large"
                onClick={() => onClick('click')}
            >
                Get the full report!
            </button>

            <p className="has-text-centered is-size-6">NO COST! NO OBLIGATION!</p>
        </div>

    </div>
);

export default ScanResultsMessage;