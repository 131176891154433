// npm imports
//
import * as React from 'react';
import { Component, RefObject } from 'react';
import { StringSchema, ObjectSchema } from 'yup';
import debounce from 'p-debounce';
import { Route, Redirect, Switch } from 'react-router-dom';
import { History } from 'history';
import debug from 'debug';

// local imports
//
import './assets/css/App.scss';
import './assets/css/ReactTooltip.scss';
import { query } from './modules/query';
import SearchResults from './modules/components/SearchResults';
import HeaderBar from './modules/components/HeaderBar';
import ScanResultsMessage from './modules/components/ScanResultsMessage';
import GSO from './modules/forms/GSO';
import GSL from './modules/forms/GSL';
import BDO from './modules/forms/BDO';
import FSL from './modules/forms/FSL';
import ConfirmationModal from './modules/components/ConfirmationModal';
import Spinner from './modules/components/Spinner';
import { query as scanQuery } from './modules/scan-query';
import { parse } from './modules/parse-location';
import { nested } from './modules/nested-property';
import './modules/types/SelectOptions.d.ts';
import { ComponentState, FormType } from './App.d';
import { Address } from './modules/types/address.d';
import { validate } from './modules/validate';
import { formatTime } from './modules/format-time';

// initialize the log function
//
const log = debug('getlisted:App');

// include the US-specific phone number formatting
// This is imported using `require` because of some quirks with typescript - if
// you attempt to import it, then typescript complains that there are no type
// definitions. It is possible to include a stub type definition for this, but
// that's a more complicated option than just using `require`.
//
require('cleave.js/dist/addons/cleave-phone.us');

/**
 * The Props type for the component
 */
interface Props {
    form:     string[];
    formType: FormType;
    history:  History<{}>;
};

/**
 * The type (alias) for the Yup scheme for the component state
 */
type ComponentStateScheme = ObjectSchema<{
    searchTerms:   string;
    name:          string;
    email:         string;
    phone:         string;
    businessPhone: string;
    businessName:  string;
    address1:      string;
    address2:      string;
    city:          string;
    state:         string;
    zip:           string;
    country:       string;
}>;

/**
 * The component scheme object type
 */
type ComponentSchemeObject = {
    [key: string]: StringSchema;
};

/**
 * The type of the query function
 */
type QueryFunction = typeof query;

/**
 * A set of mock query results used in development
 */
let mockQueryResults = process.env.NODE_ENV === 'development'
    ? require('./mock-query-results')
    : [];

/**
 * Determine if the current browser is IOS (iPhone)
 * @return `true` if the browser is IOS, `false` otherwise
 */
const isIOS = () => /iPad|iPhone|iPod/i.test(navigator.userAgent);

/**
 * The initial state for the component
 */
const initialState: ComponentState = {
    currentFormType: 'gsl',
    searchTerms:     '',
    name:            '',
    email:           '',
    phone:           '',
    businessPhone:   '',
    businessName:    '',
    address1:        '',
    address2:        '',
    city:            '',
    state:           '',
    country:         'us',
    zip:             '',
    results:         [],
    hasBlurred:      {
        searchTerms:   false,
        name:          false,
        email:         false,
        businessPhone: false,
        businessName:  false,
        address1:      false,
        address2:      false,
        city:          false,
        state:         false,
        zip:           false,
    },
    errors:                {},
    modalIsShowing:        false,
    selectedResult:        null,
    iframeSrc:             null,
    scanId:                null,
    form:                  [],
    formType:              undefined,
    isScanning:            false,
    scanIsComplete:        false,
    searchFieldsEnabled:   false,
    searchIsFocused:       false,
    windowWidth:           0,
    windowHeight:          0,
    thankYouPageIsShowing: false,
};

/**
 * Get the full height (including padding & margins) of a DOM element
 *
 * Adapted from https://stackoverflow.com/a/10787807/2008384
 *
 * @param element The DOM element
 * @return the total height of the element, or undefined if it cannot be
 * determined
 */
const getHeight = (element: HTMLElement): number | undefined => {
    log('getHeight: element class=%s, id=%s', element.id, element.className);
    // return undefined if we don't have a document default view object
    //
    if (!document.defaultView) {
        return undefined;
    }

    // get the element's computed style
    //
    const computedStyle = document.defaultView.getComputedStyle(element, '');

    // did we get the styles? if not, return undefined
    //
    if (!computedStyle) {
        return undefined;
    }

    // determine the height of the element
    //
    const height = parseInt(computedStyle.getPropertyValue('height'), 10) || 0;
    log('got element height = %d', height);

    // determine the top/bottom margins of the element
    //
    const topMargin = parseInt(computedStyle.getPropertyValue('margin-top'), 10) || 0;
    const bottomMargin = parseInt(computedStyle.getPropertyValue('margin-bottom'), 10) || 0;
    log('got element margins: top = %d, bottom = %d', topMargin, bottomMargin);

    // return the full height
    //
    const fullHeight = height + topMargin + bottomMargin;
    log('full height = %d', fullHeight);

    return fullHeight;
};

/**
 * The App component
 * @extends Component
 */
class App extends Component<Props, ComponentState> {
    /**
     * The component state
     */
    readonly state: ComponentState = initialState;

    /**
     * The query function
     */
    private query: QueryFunction;

    /**
     * A flag indicating whether this is a sales lead form
     */
    private isSalesLeadForm: boolean;

    /**
     * The function to remove the history listener
     */
    private unlisten?: () => void;

    /**
     * The reference to the search input element
     *
     * This is used to do some DOM-related things like scroll the input to the
     * top of the screen on mobile when focussed.
     */
    private searchElementRef: RefObject<HTMLInputElement>;

    /**
     * The timer object for the "click for full report" timeout
     */
    private fullReportTimer: NodeJS.Timeout | undefined;

    /**
     * A timer value used to track how long the user stayed on the "click for
     * full report" page
     */
    private startTime?: number;

    /**
     * Construct the component instance
     * @param props The component properties
     */
    constructor(props: Props) {
        super(props);
        log('App ctor');

        // initialize a debounced version of the fetch method
        //
        this.query = debounce<[string], Address[]>(
            (queryString: string) => query(queryString),
            100
        );

        // determine if this is a sales lead form
        //
        this.isSalesLeadForm = ['gsl', 'fsl'].includes(this.props.formType);

        // create the search element reference
        //
        this.searchElementRef = React.createRef();

        // set the current form type
        //
        this.state.currentFormType = this.props.formType

        log('App ctor: component state = %O', this.state);

        // set the page class based on the current form type
        //
        this.setPageClass(props.formType);
    }

    /**
     * Handle the "component did mount" event
     *
     * This method simply runs the validator for the first time, so validation
     * error messages are set for all initial values
     *
     * @return A promise to return once the component is initialized
     */
    componentDidMount = (): Promise<ComponentState> => {
        // get the initial window size into the state
        //
        log('App.componentDidMount: calling initial resize event to get initial size');
        return this.onWindowResize()

            // ...then add an event listener for window resize events
            //
            .then(() => {
                log('App.componentDidMount: adding resize event handler');
                window.addEventListener('resize', this.onWindowResize);
            })

            // ...then add the history listener and save the "unlisten" function
            // to remove it when the component unmounts
            //
            .then(() => {
                log('App.componentDidMount: adding history change event handler');
                this.unlisten = this.props.history.listen(this.onHistoryChange);
            })

            // ...then update the form type from the properties
            //
            .then(() => {
                log('App.componentDidMount: setting form type = %s', this.props.formType);
                return this.updateState({ formType: this.props.formType });
            })

            // ...then update the form elements
            //
            .then(() => {
                log('App.componentDidMount: updating form elements');
                return this.updateForm();
            })

            // ...then validate the form for the first time
            //
            .then(() => {
                log('App.componentDidMount: performing initial validation');
                return this.validate();
            });
    };

    /**
     * Handle the "component is about to unmount" event
     */
    componentWillUnmount = (): void => {
        // remove the resize event listener
        //
        log('App.componentWillUnmount: removing resize handler');
        window.removeEventListener('resize', this.onWindowResize);

        // do we have a history event "remover"? if so, use it
        //
        if (this.unlisten) {
            log('App.componentWillUnmount: removing history event handler');
            this.unlisten();
        }
    };

    /**
     * Handle the "component did update" event
     * @param prevProps The previous component properties
     * @param prevState The previous component state
     * @return A promise to return after the state is updated
     */
    componentDidUpdate = (prevProps: Props, prevState: ComponentState) => {
        log(
            'App.componentDidUpdate: checking for path change: does %s = %s?',
            this.state.pathname, prevProps.history.location.pathname
        );
        return this.state.pathname !== prevProps.history.location.pathname
            ? this.updateState({ pathname: this.props.history.location.pathname })
            : Promise.resolve();
    };

    /**
     * Handle a history change event
     *
     * This simply updates the pathname in the state if it has changed
     *
     * @param location The new history location
     * @return A promise to return once the state is updated
     */
    private onHistoryChange = (location: { pathname: string }): Promise<void> => {
        // has the pathname changed? if so, update the state
        //
        log('App.onHistoryChange: checking for path change; %s = %s?',
            location.pathname, this.state.pathname
        );

        // has the path changed?
        //
        if (location.pathname !== this.state.pathname) {
            // is the new path neither GSO nor GSL? If so, clean up the Google
            // Tag Manager script tags
            //
            if (location.pathname !== 'gso' && location.pathname !== 'gsl') {
                // find the script element that the Google code injected into
                // the page
                //
                const script = document.querySelector(
                    // eslint-disable-next-line max-len
                    `script[src="https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}"]`
                );

                // did we find a script tag? if so, remove it from the DOM
                //
                if (script) {
                    script.remove();
                }
            }

            // add the appropriate class to the DOM for this page
            //
            this.setPageClass(location.pathname.replace(/[^0-9a-z]/gi, ''));

            // update the pathname in the state
            //
            log('App.onHistoryChange: history has changed; updating state');
            return this.updateState({ pathname: location.pathname })
                .then(() => Promise.resolve());
        }

        // otherwise, just resolve the promise
        //
        log('App.onHistoryChange: no path change');
        return Promise.resolve();
    }

    /**
     * Handle a change to one of the values
     * @param field The field to change
     * @param value The new value
     * @return A promise to return once the state is updated and validated
     */
    private onChangeValue = (field: string, value: string): Promise<ComponentState> => {
        log('App.onChangeValue: field=%s, value=%s', field, value);
        // update the state with the new value for the field
        //
        return this.updateState({ [field]: value })

            // ...then validate the component state
            //
            .then(() => this.validate())
    };

    /**
     * Handle a change to the search element
     * @param value The search terms
     * @return a promise to return once the change has been handled
     */
    private onChangeSearch = (value: string): Promise<void> => {
        log('App.onChangeSearch: value=%s', value);
        // change the search terms in the state
        //
        return this.onChangeValue('searchTerms', value)

            // ...then execute the fetch if the value is long enough
            //
            .then(() => {
                // is the REACT_APP_MOCK variable set? if so, use the mock query
                // results
                //
                if (process.env.REACT_APP_MOCK) {
                    log('App.onChangeSearch: using mock results');
                    return Promise.resolve(value.length > 3 ? mockQueryResults : []);
                }

                // otherwise, execute a query if the value is long enough
                //
                log(
                    'App.onChangeSearch: term = %s (len=%d), executing query = %s',
                    value, value.length, value.length > 3 ? 'true' : 'false'
                );
                return value.length > 3 ? this.query(value) : Promise.resolve([]);
            })

            // ...then update the state with the results
            //
            .then((results) => this.updateState({ results }))

            // ...then call the search input on focus handler (to jump the input
            // to the top of the screen if necessary)
            //
            .then(() => this.onFocusSearch());
    };

    /**
     * Handle a blur event for an input element
     * @param field The field that has blurred
     * @return A promise to return once the state is updated
     */
    private onBlur = (field: string) => {
        log('App.onBlur: field = %s', field);
        return this.updateState({ hasBlurred: { ...this.state.hasBlurred, ...{ [field]: true } } });
    };

    /**
     * Handle a click of one of the search results
     * @param result The search result which was clicked
     * @return A promise to return once the state is updated and the page has
     *         been redirected to the confirmation modal
     */
    private onClick = (result: Address) => {
        log('App.onClick: result = %O', result);
        return this.updateState({ modalIsShowing: true, selectedResult: result })
            .then(() => {
                log('App.onClick: redirecting to %s', `/conf${window.location.search}`);
                this.props.history.push(`/conf${window.location.search}`);
            });
    };

    /**
     * Handle a click of the modal dialog "OK" button
     * @param address The search result which was clicked
     * @return A promise to return once the state is updated
     */
    private onClickModalOK = (address: Address): Promise<void> => {
        log('App.onClickModalOK: address = %O', address);
        // get the query arguments from the results
        //
        const query = scanQuery(
            address,
            this.state.formType,
            this.state.name,
            this.state.email,
            this.state.phone,
            window.location.search
        );
        log('App.onClickModalOK: built query string = %s', query);

        // set the appropriate scannning state and update the address fields
        //
        return this.updateState({
            isScanning:     true,
            scanIsComplete: false,
            modalIsShowing: false,
            bodyHeight:     undefined,
            ...address,
        })

            // ...then fetch the scan results URL from the `/api/scan` route
            //
            .then(() => {
                log('App.onClickModalOK: fetching from /api/scan?%s', query);
                return fetch(`/api/scan?${query}`);
            })

            // ...then extract the text of the response
            //
            .then((res) => res.text())

            .then((scanId) => this.updateState({ scanId }))

            // ...then handle the ID we got from the server
            //
            .then(() => {
                log('App.onClickModalOK: got scan ID = %s', this.state.scanId);
                // are we in production? if not, return the dummy page URL
                //
                if (process.env.NODE_ENV !== 'production') {
                    return 'http://localhost:8000/getlisted.html';
                }

                // are we one the search only form? if so, return the full scan
                // report from areyoulisted.thenala.com; otherwise, return the
                // truncated one from getlisted.thenala.com
                //
                return this.state.formType === 'gso'
                    ? `https://areyoulisted.thenala.com/search/${this.state.scanId}`
                    : `https://getlisted.thenala.com/search/${this.state.scanId}`;
            })

            // ...then update the state with the iframe src URL
            //
            .then((iframeSrc) => this.updateState({
                isScanning:     false,
                scanIsComplete: true,
                modalIsShowing: false,
                iframeSrc,
            }))

            // ...then redirect to the scan page
            //
            .then(() => {
                log('App.onClickModalOK: redirecting to /scan%s', window.location.search);
                this.props.history.push(`/scan${window.location.search}`);
            })

            // ...then submit the sales lead if it's form type GSO; otherwise,
            // do nothing
            //
            .then(() => {
                if (this.state.formType === 'gso') {
                    return this.submitSalesLead('n/a', 'click')
                        .then(() => Promise.resolve());
                }
                return Promise.resolve();
            })

            // ...then set a timeout to "click" the full report button
            //
            .then(() => {
                // save the current timestamp
                //
                this.startTime = Date.now();

                // calculate the interval
                //
                const interval = (
                    parseFloat(process.env.REACT_APP_FULL_REPORT_TIMEOUT || '5')
                ) * 60 * 1000;
                log('App.onClickModalOK: timeout interval = %d seconds', interval /1000);

                // are we in the "production" environment? if so, set the timer
                //
                if (process.env.NODE_ENV === 'production') {
                    log('App.onClickModalOK: setting timeout handler %d seconds', interval / 1000);
                    this.fullReportTimer = setTimeout(() => {
                        this.onClickFullReport('timeout');
                    }, interval);
                }

                // add the "onNavigateAway" event handler
                //
                window.onbeforeunload = this.onNavigateAway;
            });
    };

    /**
     * Handle a "navigate away" action
     *
     * This is used after the "click to see full report" button is displayed.
     * This will result in the final details being sent to the server with a
     * note saying the client navigated away.
     */
    private onNavigateAway = () => {
        this.onClickFullReport('nav');
    };

    /**
     * Handle a click of the "cancel" button on the modal
     * @param address The address which was being reviewed when cancel was
     *                clicked
     * @return A promise to return once the state is updated
     */
    private onClickModalCancel = (address?: Address): Promise<ComponentState> => {
        log('App.onClickModalCancel: address = %O', address);
        // determine the new location to redirect to
        //
        const newLocation = this.state.formType === 'gso' ? 'bdo' : 'fsl';
        log('App.onClickModalCancel: newLocation = %s', newLocation);

        // build the new component state
        //
        let newState = {
            modalIsShowing: false,
            results:        [],
            iframeSrc:      null,
        };

        // do we have address values? if so, add them to the new state
        //
        if (address) {
            newState = { ...newState, ...address };
        }
        log('App.onClickModalCancel: updating state = %O', newState);

        // update the component state with the new values
        //
        return this.updateState(newState)

            // ...then redirect to the form page
            //
            .then(() => {
                log(
                    'App.onClickModalCancel: redirecting to /%s/%s',
                    newLocation, window.location.search
                );
                return this.props.history.push(`/${newLocation}${window.location.search}`);
            })

            // ...then update the form (in case the form type changed)
            //
            .then(() => {
                log('App.onClickModalCancel: updating form');
                return this.updateForm(newLocation);
            })

            // ...then update the form type
            //
            .then(() => this.updateState({ currentFormType: newLocation }))

            // ...then validate the values
            //
            .then(() => {
                log('App.onClickModalCancel: validating');
                return this.validate();
            })
    };

    /**
     * Handle a click of the "scan" button
     * @return A promise to return once the scan is run and the state updated
     */
    private onClickScan = (): Promise<void> => {
        // put together the address information
        //
        const address: Address = {
            placeId:       nested(this.state, 'selectedResult.placeId'),
            name:          this.state.name,
            email:         this.state.email,
            businessName:  this.state.businessName,
            businessPhone: this.state.businessPhone,
            phone:         this.state.phone,
            address1:      this.state.address1,
            address2:      this.state.address2,
            city:          this.state.city,
            state:         this.state.state,
            zip:           this.state.zip,
            country:       this.state.country.toLowerCase(),
        };
        log('App.onClickScan: address = %O', address);

        // call onClickModalOK to perform scan
        //
        log('App.onClickScan: calling onClickModalOK to initiate scan');
        return this.onClickModalOK(address);
    };

    /**
     * Handle a window resize event
     */
    private onWindowResize = (): Promise<void> => {
        // determine the dimensions
        //
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        let bodyHeight: number | string = 'initial';
        log(
            'App.onWindowResize: got initial values = %O',
            { windowWidth, windowHeight, bodyHeight }
        );

        // declare a variable to calculate the height change
        //
        let deltaHeight = 0;

        // are we on mobile (ie. narrow screen)? do we have a search element
        // reference? if so, calculate the scroll details
        //
        if (
            this.state.windowWidth < 768
            && this.searchElementRef
            && this.searchElementRef.current
        ) {
            log('App.onWindowResize: we appear to be on mobile; calculating height');
            // get the name/email/phone inputs height & add it to the height
            // change
            //
            const inputs = document.getElementsByClassName('name-email-phone-inputs');
            for (let idx = 0; idx < inputs.length; idx++) {
                deltaHeight += getHeight(inputs[idx] as HTMLElement) || 0;
            }

            // get the HeaderBar height & add it to the height change
            //
            const logo = document.getElementsByClassName('HeaderBar');
            deltaHeight += getHeight(logo[0] as HTMLElement) || 0;

            // get the copy text height & add it to the height change
            //
            const copy = document.getElementsByClassName('form-copy');
            deltaHeight += getHeight(copy[0] as HTMLElement) || 0;

            // get the top of the header & subtract that from the change in
            // height
            //
            // deltaHeight -= (logo[0] as HTMLElement).getBoundingClientRect().top;
            log('App.onWindowResize: height delta = %d', deltaHeight);

            // the 9px below is a fudge
            //
            bodyHeight = `${windowHeight + deltaHeight - 9}px`;
            log('App.onWindowResize: recalculate body height = %s', bodyHeight);
        }

        // update the state with the new dimensions
        //
        log(
            'App.onWindowResize: update state with window dimensions = %O',
            { windowWidth, windowHeight, bodyHeight }
        );
        return this.updateState({ windowWidth, windowHeight, bodyHeight })

            // ...then scroll into view if the search element is focused
            //
            .then(() => {
                if (
                    this.state.searchIsFocused
                    && this.searchElementRef.current
                    && this.searchElementRef.current.parentElement
                    && this.searchElementRef.current.parentElement.parentElement
                ) {
                    log('App.onWindowResize: scrolling into view');
                    this.searchElementRef.current!
                        .parentElement!
                        .parentElement!
                        .scrollIntoView(true);
                }
            })
    };

    /**
     * Handle a focus event on the search input
     * @return A promise to return once the state is updated & the input element
     *         scrolled into view
     */
    private onFocusSearch = (): Promise<void> => {
        // find the node representing the full search input (including
        // label & caption)
        //
        const fieldNode = this.searchElementRef.current!.parentElement!.parentElement!;

        // set the "search is focused" flag
        //
        log('App.onFocusSearch: updating searchIsFocused state');
        return this.updateState({ searchIsFocused: true })

            // ...then call the onWindowResize to calculate the window size
            //
            .then(() => {
                log('App.onFocusSearch: recalculating window size');
                return this.onWindowResize();
            })

            // ...then scroll the search element into view if it exists
            //
            .then(() => {
                log('App.onFocusSearch: scrolling into view');
                return fieldNode && fieldNode.scrollIntoView(true);
            })
    };

    /**
     * Handle a blur event on the search input
     */
    private onBlurSearch = (): Promise<ComponentState> => {
        log('App.onBlurSearch');
        return this.updateState({ searchIsFocused: false });
    }

    /**
     * Handle a click of the "send full report" button
     * @param response The client response (either a click or "nav" if they
     * navigate away from the page)
     * @returns A promise to return once the click is handled
     */
    private onClickFullReport = (response: 'click' | 'nav' | 'timeout'): Promise<void> => {
        log('App.onClickFullReport: response = %s', response);
        // clear the timer
        //
        if (this.fullReportTimer) {
            log('App.onClickFullReport: clearing timer');
            clearTimeout(this.fullReportTimer);
        }

        // clear the "before unload" handler
        //
        if (window.onbeforeunload) {
            log('App.onClickFullReport: clearing window onBeforeUnload handler');
            window.onbeforeunload = null;
        }

        // determine the elapsed time in seconds
        //
        const elapsed: string = this.startTime
            ? formatTime((Date.now() - this.startTime) / 1000)
            : 'unknown';
        log('App.onClickFullReport: calculated elapsed time = %s', elapsed);

        // submit the sales lead to the server
        //
        log('App.onClickFullReport: submitting sales lead');
        return this.submitSalesLead(elapsed, response)

            // ...then update the state to show the "thank you" page if the user
            // clicked
            //
            .then(() => this.updateState({ thankYouPageIsShowing: response === 'click' }))

            // ...then redirect to the thank you route if the user clicked
            //
            .then(() => response === 'click'
                ? this.props.history.push('/thankyou')
                : Promise.resolve()
            );
    };

    /**
     * Submit the sales lead to the server
     * @param elapsed The elapsed time in seconds (or "unknown")
     * @param response The user response type
     * @returns A promise to return the response from the server (usually 'ok')
     */
    private submitSalesLead = (
        elapsed: string,
        response: 'click' | 'nav' | 'timeout'
    ): Promise<string> => {
        // build the request body
        //
        const body = {
            formType:      this.state.formType,
            params:        window.location.search,
            response,
            elapsed,
            scanId:        this.state.scanId,
            placeId:       nested(this.state, 'selectedResult.placeId') || '',
            name:          nested(this.state, 'name'),
            email:         nested(this.state, 'email'),
            businessName:  nested(this.state, 'businessName'),
            businessPhone: nested(this.state, 'businessPhone'),
            phone:         nested(this.state, 'phone'),
            address1:      nested(this.state, 'address1'),
            address2:      nested(this.state, 'address2'),
            city:          nested(this.state, 'city'),
            state:         nested(this.state, 'state'),
            zip:           nested(this.state, 'zip'),
            country:       nested(this.state, 'country'),
        };

        // execute the POST request to the "send full report" endpoint
        //
        return fetch('/api/send-full', {
            method:  'POST',
            headers: { 'Content-Type': 'application/json' },
            body:    JSON.stringify(body),
        })

        // ...then get the response from the client
        //
            .then((res) => res.text())
    };

    /**
     * Set the page class (ie. class on the HTML element)
     * @param page The page name/URI
     */
    private setPageClass = (page: string) => {
        // get the page HTML element
        //
        const el = document.getElementsByTagName('html')[0];

        // build a list of old "page-*" classes to remove
        //
        const classesToRemove: string[] = [];
        el.classList.forEach((val) => {
            if (/^page-/.test(val)) {
                classesToRemove.push(val);
            }
        });

        // remove the incorrect classes
        //
        classesToRemove.forEach((val) => {
            el.classList.remove(val);
        });

        // add the new page class
        //
        el.classList.add(`page-${page}`);
    };

    /**
     * Update the form with the correct elements for the type
     * @return A promise to return once the state is updated with the new form
     *         elements
     */
    private updateForm =
        (newForm: FormType | undefined = this.state.formType): Promise<ComponentState> => {
            // parse the form type & extract the new form elements
            //
            const form = parse(`/${newForm || 'gsl'}`).form;
            log('App.updateForm: form type = %s; new form %O', newForm, form);

            // update the state
            //
            log('App.updateForm: updating the state');
            return this.updateState({ form });
        };

    /**
     * Update the state, returning a promise
     * @param newState The new state value(s) (shallow-merged with existing state)
     * @return        A promise to return once the state is updated
     */
    private updateState = (newState: Object): Promise<ComponentState> =>
        new Promise((resolve) =>
            this.setState(
                (prevState: ComponentState) => ({ ...prevState, ...newState }),
                resolve
            )
        );

    /**
     * Validate the component state
     * @return A promise to return once the state is updated
     */
    private validate = (): Promise<ComponentState> =>
        // validate the state & props, based on country & form type
        //
        validate(
            this.state.country,
            this.state.currentFormType || 'gsl',
            { ...this.state, ...this.props }
        )

            // ...then update the state with the results
            //
            .then(({ isValid, errors }) => this.updateState({
                isValid,
                errors,
                searchFieldsEnabled: isValid,
            }));

    /**
     * Render the GSO (Google Search Only) form
     * @return The GSO component
     */
    renderGSO = (): JSX.Element => (
        <GSO
            disabled={!this.state.searchFieldsEnabled}
            hasBlurred={this.state.hasBlurred.searchTerms}
            error={this.state.errors.searchTerms}
            value={this.state.searchTerms}
            searchElementRef={this.searchElementRef}
            showImages={this.state.results && this.state.results.length === 0}
            onBlur={this.onBlur}
            onFocusSearch={this.onFocusSearch}
            onChange={this.onChangeSearch}
        />
    );

    /**
     * Render the GSL (Google Search + Sales Lead) form
     * @return The GSL component
     */
    renderGSL = (): JSX.Element => (
        <GSL
            nameHasBlurred={this.state.hasBlurred.name}
            emailHasBlurred={this.state.hasBlurred.email}
            phoneHasBlurred={this.state.hasBlurred.phone}
            searchTermsHasBlurred={this.state.hasBlurred.searchTerms}
            nameError={this.state.errors.name}
            emailError={this.state.errors.email}
            phoneError={this.state.errors.phone}
            searchTermsError={this.state.errors.searchTerms}
            name={this.state.name}
            email={this.state.email}
            phone={this.state.phone}
            searchTerms={this.state.searchTerms}
            searchFieldsEnabled={this.state.searchFieldsEnabled}
            searchElementRef={this.searchElementRef}
            showImages={this.state.results && this.state.results.length === 0}
            onBlur={this.onBlur}
            onChange={this.onChangeValue}
            onFocusSearch={this.onFocusSearch}
            onChangeSearch={this.onChangeSearch}
        />
    );

    /**
     * Render the BDO (Business Details Only) form
     * @return The BDO component
     */
    renderBDO = (): JSX.Element => (
        <BDO
            businessNameHasBlurred={this.state.hasBlurred.businessName}
            businessPhoneHasBlurred={this.state.hasBlurred.businessPhone}
            address1HasBlurred={this.state.hasBlurred.address1}
            address2HasBlurred={this.state.hasBlurred.address2}
            cityHasBlurred={this.state.hasBlurred.city}
            stateHasBlurred={this.state.hasBlurred.state}
            zipHasBlurred={this.state.hasBlurred.zip}
            businessNameError={this.state.errors.businessName}
            businessPhoneError={this.state.errors.businessPhone}
            address1Error={this.state.errors.address1}
            address2Error={this.state.errors.address2}
            cityError={this.state.errors.city}
            stateError={this.state.errors.state}
            zipError={this.state.errors.zip}
            businessName={this.state.businessName}
            businessPhone={this.state.businessPhone}
            address1={this.state.address1}
            address2={this.state.address2}
            city={this.state.city}
            state={this.state.state}
            country={this.state.country}
            zip={this.state.zip}
            onBlur={this.onBlur}
            onChange={this.onChangeValue}
            onClickScan={this.onClickScan}
        />
    );

    /**
     * Render the FSL (Full Sales Lead) form
     * @return The FSL component
     */
    renderFSL = (): JSX.Element => (
        <FSL
            nameHasBlurred={this.state.hasBlurred.name}
            emailHasBlurred={this.state.hasBlurred.email}
            businessNameHasBlurred={this.state.hasBlurred.businessName}
            businessPhoneHasBlurred={this.state.hasBlurred.businessPhone}
            phoneHasBlurred={this.state.hasBlurred.phone}
            address1HasBlurred={this.state.hasBlurred.address1}
            address2HasBlurred={this.state.hasBlurred.address2}
            cityHasBlurred={this.state.hasBlurred.city}
            stateHasBlurred={this.state.hasBlurred.state}
            zipHasBlurred={this.state.hasBlurred.zip}
            nameError={this.state.errors.name}
            emailError={this.state.errors.email}
            businessNameError={this.state.errors.businessName}
            businessPhoneError={this.state.errors.businessPhone}
            phoneError={this.state.errors.phone}
            address1Error={this.state.errors.address1}
            address2Error={this.state.errors.address2}
            cityError={this.state.errors.city}
            stateError={this.state.errors.state}
            zipError={this.state.errors.zip}
            name={this.state.name}
            email={this.state.email}
            businessName={this.state.businessName}
            businessPhone={this.state.businessPhone}
            phone={this.state.phone}
            address1={this.state.address1}
            address2={this.state.address2}
            city={this.state.city}
            state={this.state.state}
            country={this.state.country}
            zip={this.state.zip}
            onBlur={this.onBlur}
            onChange={this.onChangeValue}
            onClickScan={this.onClickScan}
        />
    );

    /**
     * Render the correct form component based on the form type
     * @param formType The form type
     * @return The appropriate React element
     */
    renderFormType = (formType: string): JSX.Element => {
        log('App.renderFormType: form type = %s', formType);
        // declare a variable to hold the form component
        //
        let form: JSX.Element;

        // render the correct form based on the form type
        //
        switch (formType) {
            case 'gso':
                form = this.renderGSO();
                break;
            case 'bdo':
                form = this.renderBDO();
                break;
            case 'fsl':
                form = this.renderFSL();
                break;
            default:
            case 'gsl':
                form = this.renderGSL();
                break;
        }

        // return the form element
        //
        return form;
    };

    /**
     * Render the correct form based on the form type, optionally showing the
     * confirmation modal
     * @param formType  The form type
     * @param showModal A flag indicating whether the modal is showing
     * @return The React element
     */
    renderForm = (formType: string, showModal: boolean): JSX.Element => (
        <div>
            {this.state.isScanning  ? null : <HeaderBar />}

            {this.state.isScanning
                ? (
                    <div>
                        <Spinner />
                        <p style={{ color: 'white' }}>Initiating Scan&hellip;</p>
                    </div>
                )
                : (
                    <React.Fragment>

                        {this.renderFormType(formType)}

                        {this.state.results.length === 0 || this.state.isScanning
                            ? null : (
                                <SearchResults
                                    maxHeight={this.calculateMaxHeight()}
                                    results={this.state.results}
                                    onClickResult={this.onClick}
                                    onClickCancel={this.onClickModalCancel}
                                />
                            )
                        }

                        {showModal && this.state.selectedResult
                            ? (
                                <ConfirmationModal
                                    address={this.state.selectedResult}
                                    formType={this.state.formType}
                                    onClickOK={this.onClickModalOK}
                                    onClickCancel={this.onClickModalCancel}
                                />
                            )
                            : null
                        }

                    </React.Fragment>
                )
            }
        </div>
    );

    /**
     * Render the scan results
     * @returns The React element
     */
    renderResults = () => {
        // is it the GSO form? if so, render the full iframe
        //
        if (this.state.formType === 'gso') {
            document.getElementsByTagName('html')[0].classList.add('no-vertical-scroll');
            return (
                <div className="scan-positioning">
                    <iframe
                        title="Scan Results"
                        src={this.state.iframeSrc || ''}
                    />
                </div>
            );
        }

        // render & return the results
        //
        return (
            <div className="scan-positioning-outer">
                <div className="scan-positioning-inner">
                    <HeaderBar />
                    <iframe
                        title="Scan Results"
                        src={this.state.iframeSrc || ''}
                    />
                    <div className="clear-section" />
                    <div className="fade-overlay-top" />
                    <div className="fade-overlay-bottom">
                        <ScanResultsMessage onClick={this.onClickFullReport} />
                    </div>
                </div>
            </div>
        );
    };

    /**
     * Render the "thank you" message
     * @returns The React element
     */
    renderThankYou = () => (
        <div className="thank-you">
            <HeaderBar />
            <h1 className="title">Thank you!</h1>
            <p>We will be contacting you with your full report within one business day.</p>
        </div>
    );

    /**
     * Calculate the max height for the search results component based on the
     * window size & form type
     * @return {number | undefined} The max height in pixels (or undefined if
     *                              the style does not apply)
     */
    private calculateMaxHeight = (): number | undefined => {
        log('App.calculateMaxHeight');
        // declare a variable to calculate the max height for the search results
        //
        let maxHeight: number | undefined;

        // is the width < 420 (ie. the mobile breakpoint)? if so calculate the
        // max height
        //
        log('App.calculateMaxHeight: windowWidth = %d', this.state.windowWidth);
        if (this.state.windowWidth <= 420) {
            // is it either the GSO or GSL form? if so, calculate the max height
            // Note that these adjustments are determined through trial & error
            //
            if (this.state.formType === 'gso') {
                // max height for search results 160 = value for Android Galaxy S9
                maxHeight = this.state.windowHeight - (isIOS() ? 395 : 160);
            } else if (this.state.formType === 'gsl') {
                // iphone 6 = 415
                // iphone 6s = 235 (178)
                //
                maxHeight = this.state.windowHeight - (isIOS() ? 235 : 160);
            }
        }

        log('App.calculateMaxHeight: maxHeight = %d', maxHeight);
        // return the result
        //
        return maxHeight;
    };

    /**
     * Render the component
     * @return The React element
     */
    render = (): JSX.Element => (
        <div className="App" style={{ height: this.state.bodyHeight || 'initial' }}>

            <div className="app-contents">

                <Switch>
                    <Route path="/gso" render={() => this.renderForm('gso', false)} />
                    <Route path="/gsl" render={() => this.renderForm('gsl', false)} />
                    <Route path="/bdo" render={() => this.renderForm('bdo', false)} />
                    <Route path="/fsl" render={() => this.renderForm('fsl', false)} />
                    {this.state.formType && this.state.selectedResult &&
                        <Route
                            path="/conf"
                            render={() =>
                                this.renderForm(this.state.formType || 'gsl', true)
                            }
                        />
                    }
                    {this.state.iframeSrc && <Route path="/scan" render={this.renderResults} />}
                    {this.state.thankYouPageIsShowing
                        && <Route path="/thankyou" render={this.renderThankYou} />
                    }
                    <Redirect to={`/${this.state.formType || 'gsl'}`} />
                </Switch>

            </div>

        </div>
    );
}

// export the component
//
export default App;
