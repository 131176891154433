/**
 * Constants for describing fields on the App component form
 */
export const GOOGLE_SEARCH = 'GOOGLE_SEARCH';
export const ADDRESS_1 = 'ADDRESS_1';
export const ADDRESS_2 = 'ADDRESS_2';
export const BUSINESS_NAME = 'BUSINESS_NAME';
export const BUSINESS_PHONE = 'BUSINESS_PHONE';
export const CITY = 'CITY';
export const EMAIL = 'EMAIL';
export const CONTACT_NAME = 'CONTACT_NAME';
export const PHONE = 'PHONE';
export const STATE = 'STATE';
export const COUNTRY = 'COUNTRY';
export const ZIP_CODE = 'ZIP_CODE';
export const SUBMIT_BUTTON = 'SUBMIT_BUTTON';
