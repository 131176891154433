// npm imports
//
import debug from 'debug';

// initialize the logging function
//
const log = debug('getlisted:format-phone');

/**
 * The type for the format function
 */
type NullableScalar = string | number | null | undefined;

/**
 * Format a phone number (10 digits) as a string
 *
 * Note that if the value does not match the appropriate format (10 digits,
 * excluding disallowed phone numbers and area coes starting with 0, 1), then
 * the original value is returned unchanged.
 *
 * @param  {NullableScalar} value The number to format
 * @return {NullableScalar}       The result
 */
export const format = (value: NullableScalar): NullableScalar => {
    log('formatting phone number: %s', value);
    // ensure that the value is a string
    //
    const num = `${value}`;

    // check the string against the regular expression & format it if it matches
    //
    return /^[2-9][0-9]{2}[2-9][0-9]{6}$/.test(num)
        ? [num.substr(0, 3), num.substr(3, 3), num.substr(6, 4),].join('-')
        : value;
};
