// npm imports
//
import { object, string, ValidationError } from 'yup';

// local imports
//
import { FormType, ErrorList } from '../App.d';

/**
 * The values type
 */
interface ValueType {
    [key: string]: any;
};

/**
 * The email validation scheme
 */
const emailScheme = string()
    .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        'Please enter a valid email address'
    )
    .required('Please enter your email');

/**
 * The phone number validation scheme
 */
const phoneScheme = string()
    .matches(/[0-9]{3}\s?[0-9]{3}\s?[0-9]{4}/, 'Please enter a valid phone number')
    .required('Please enter a phone number');

/**
 * The validation scheme
 */
const scheme = {
    gso: {},
    gsl: {
        name:  string().required('Please enter your name'),
        email: emailScheme,
        phone: phoneScheme,
    },
    bdo: {
        businessName:   string().required('Please enter your business name'),
        businessPhone:  phoneScheme,
        address1:       string().required('Please enter your business address'),
        address2:       string().nullable(),
        city:           string().required('Please enter your city'),
        state:          string(), // this will be amended in the getScheme function
        country:        string(),
        zip:            string(), // this will be amended in the getScheme function
    },
    fsl: {
        name:          string().required('Please enter your name'),
        email:         emailScheme,
        phone:         phoneScheme,
        businessName:   string().required('Please enter your business name'),
        businessPhone:  phoneScheme,
        address1:       string().required('Please enter your business address'),
        address2:       string().nullable(),
        city:           string().required('Please enter your city'),
        state:          string(), // this will be amended in the getScheme function
        country:        string(),
        zip:            string(), // this will be amended in the getScheme function
    },
};

/**
 * Get the Yup validation scheme, modifying it for the country & form type
 * @param country The country
 * @param formType The form type
 * @returns A Yup validation scheme
 */
const getScheme = (country: string, formType: FormType) => {
    // revise the state validation scheme
    //
    scheme.bdo.state = string()
        .required(`Please enter your ${country === 'us' ? 'state' : 'province'}`);
    scheme.fsl.state = string()
        .required(`Please enter your ${country === 'us' ? 'state' : 'province'}`);

    // revise the zip code validation scheme
    //
    scheme.bdo.zip = country.toLowerCase() === 'us'
        ? string()
            .matches(/[0-9]{5}/, 'Please enter a valid zip code')
            .required('Please enter your zip code')
        : string()
            .matches(/[A-Z][0-9][A-Z] [0-9][A-Z][0-9]/i, 'Please enter a valid postal code')
            .required('Please enter your postal code');
    scheme.fsl.zip = country.toLowerCase() === 'us'
        ? string()
            .matches(/[0-9]{5}/, 'Please enter a valid zip code')
            .required('Please enter your zip code')
        : string()
            .matches(/[A-Z][0-9][A-Z] [0-9][A-Z][0-9]/i, 'Please enter a valid postal code')
            .required('Please enter your postal code');

    // return the appropriate scheme
    //
    return object(scheme[formType]);
};

/**
 * Validate a set of values against the validation scheme
 * @param country The country
 * @param formType The form type
 * @param values The values to validate
 * @returns A promise to return the validation results
 */
export const validate = (
    country: string,
    formType: FormType,
    values: ValueType
): Promise<{ isValid: boolean, errors: ErrorList }> =>
    // get the scheme
    //
    getScheme(country, formType)
        // validate the values against the scheme
        //
        .validate(values, { abortEarly: false })

        // ...then return success (since there's no error)
        //
        .then(() => ({ isValid: true, errors: {} }))

        // catch & handle the errors
        //
        .catch((err) => {
            // declare a variable to build the list of errors
            //
            const errors: ErrorList = {};

            if (err.inner) {
                // iterate over the errors & build the list
                //
                err.inner.forEach((inner: ValidationError) => {
                    errors[inner.path] = inner.message;
                });
            }

            // return the results
            //
            return { isValid: false, errors };
        });
