// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';

// local imports
//
import '../../assets/css/SearchResults.scss';
import AddressDisplay from './AddressDisplay';

// type imports
//
import { Address } from '../types/address.d';

// the component property types
//
interface Props {
    result:  Address,
    onClick: (result: Address) => void;
};

/**
 * The SearchResultsItem component
 * @return The React component
 */
const SearchResultsItem: FunctionComponent<Props> = ({ result, onClick }): JSX.Element => (
    <li className="search-results-item" onClick={() => onClick(result)}>
        <AddressDisplay left address={result} />
    </li>
);

// export the component
//
export default SearchResultsItem;
