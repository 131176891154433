/**
 * Get a nested property from an object, specifying the path to the property
 * with "dot-separated" property names
 * @param object the object from which to get the property
 * @param property the property path
 * @returns The value found at that path in the object, or undefined if the
 * object cannot be "parsed" or the path is not found
 */
export const nested = (object: any, property: string | number): any => {
    // is `object` actually an object? if so, attempt to traverse the path
    //
    if (object && typeof object === 'object') {
        // is the property a string?
        //
        if (typeof property === 'string' && property !== '') {
            // it's a string! spli the path & attempt to walk the object
            //
            const split = property.split('.');
            return split.reduce((obj, prop) => obj && obj[prop], object);
        } else if (typeof property === 'number') {
            // it's a number. Treat object like an array and return whatever we
            // find
            //
            return object[property];
        }
    }

    // it's not an object or it's not a valid path - just return the original
    // object
    //
    return object;
};