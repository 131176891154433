// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import debug from 'debug';

// local imports
//
import '../../assets/css/TextInput.scss';
import Caption from './Caption';
import { states } from '../constants/us-states';
import { provinces } from '../constants/canadian-provinces';
import { CountryType } from '../../App.d';
import '../types/SelectOptions.d.ts';

// initialize the log function
//
const log = debug('getlisted:StateSelect');

/**
 * The event object type
 */
interface Event {
    target: { value: string };
};

/**
 * The component properties type
 */
interface Props {
    id?:          string;
    country:      CountryType;
    field:        string;
    label?:       string;
    caption?:     string;
    helpText?:    string;
    hasBlurred?:  boolean;
    required?:    boolean;
    error?:       string;
    value:        string;
    placeholder?: string;
    onBlur:       (field: string) => void;
    onChange:     (field: string, value: string) => Promise<void>;
};

/**
 * The properties for the select element
 */
interface SelectElementProps {
    id?: string;
    className: string;
    value: string;
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

/**
 * The properties for the caption element
 */
interface CaptionProps {
    id?: string;
    className: string;
    caption?: string;
    error?:       string;
    hasBlurred?:  boolean;
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
};

/**
 * The StateSelect component
 * @return The React element
 */
const StateSelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
    log('rendering StateSelect component');
    // build the props for the select element
    //
    const selectProps: SelectElementProps = {
        className: classnames({ 'is-danger': props.hasBlurred && props.error }),
        value:     props.value,
        onChange:  (ev: React.ChangeEvent<HTMLSelectElement>) =>
            props.onChange(props.field, ev.target.value)
                .then(() => props.onBlur(props.field)),
    };

    // do we have an onBlur handler? if so, add it to the select element props
    //
    if (props.onBlur) {
        selectProps.onBlur = () => props.onBlur(props.field);
    }

    // build the props for the error element
    //
    const captionProps: CaptionProps = {
        className:  classnames(
            'help',
            props.hasBlurred && props.error ? 'is-danger' : 'has-text-white'
        ),
        caption:    props.caption,
        hasBlurred: props.hasBlurred,
        error:      props.error,
    };

    // do we have an ID? if so, add it to the select & error elements
    //
    if (props.id) {
        selectProps.id = `${props.id}-select`;
        captionProps.id = `${props.id}`;
    }

    // render a label element if a label was provided
    //
    const labelElement = props.label ? <label className="label">{props.label}</label> : null;

    log('build select element props: %O', selectProps);
    log('build caption element props: %O', captionProps);
    // render and return the component
    //
    return (
        <div className="field TextInput">
            {labelElement}
            {props.required ? <span className="required-asterisk">*</span> : null}
            {props.helpText
                ? (
                    <span
                        id={`${props.field}-help-icon`}
                        className="jam jam-help"
                        data-for="react-tooltip"
                        data-tip="custom show"
                        data-event="click focus"
                    />
                )
                : null
            }
            {props.helpText
                ? (
                    <ReactTooltip
                        id="react-tooltip"
                        type="light"
                        globalEventOff="click"
                    >
                        {props.helpText}
                    </ReactTooltip>
                )
                : null
            }
            <div className="control">
                <div className="select is-fullwidth">
                    <select {...selectProps}>
                        <option value="">
                            Select&hellip;
                        </option>
                        {(props.country.toLowerCase() === 'us' ? states : provinces)
                            .map((option: SelectOption) => (
                                <option key={`Select-state-${option.value}`} value={option.value}>
                                    {option.name}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <Caption {...captionProps} />
        </div>
    );
};

// export the component
//
export default StateSelect;
