// type imports
//
import '../types/SelectOptions.d';

/**
 * The list of Canadian provinces as a SelectOptionList
 */
export const provinces: SelectOptionList = [
    { value: 'AB', name: 'Alberta' },
    { value: 'BC', name: 'British Columbia' },
    { value: 'MB', name: 'Manitoba' },
    { value: 'NB', name: 'New Brunswick' },
    { value: 'NL', name: 'Newfoundland and Labrador' },
    { value: 'NS', name: 'Nova Scotia' },
    { value: 'NT', name: 'Northwest Territories' },
    { value: 'NU', name: 'Nunavut' },
    { value: 'ON', name: 'Ontario' },
    { value: 'PE', name: 'Prince Edward Island' },
    { value: 'QC', name: 'Québec' },
    { value: 'SK', name: 'Saskatchewan' },
    { value: 'YT', name: 'Yukon' },
];
