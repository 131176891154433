/**
 * Format a list of words as an English list (eg. [a, b, c] => "a, b, and c")
 * @param values The list of values to format
 * @returns The formatted list
 */
export const formatList = (values: string | string[] | undefined): string => {
    // is it undefined or a string? if so, return it as is (or as an empty
    // string)
    //
    if (typeof values === 'undefined' || typeof values === 'string') {
        return values || '';
    }

    // otherwise, it's an array. Return the appropriate value based on the
    // length
    //
    if (values.length === 0) {
        // no values, return an empty string
        //
        return '';
    } else if (values.length === 1) {
        // one value, return it as is
        //
        return values[0];
    } else if (values.length === 2) {
        // two values, return it with "and"
        //
        return values.join(' and ');
    } else {
        // more than two values, return a comma separated list, with "and"
        // before the last entry
        //
        return [
            values.slice(0, values.length - 1).join(', '),
            values[values.length - 1],
        ].join(', and ')
    }
}
