// npm imports
//
import * as React from 'react';
import { FunctionComponent, RefObject } from 'react';

// local imports
//
import '../../assets/css/GSL.scss';
import Columns from '../components/Columns';
import Column from '../components/Column';
import TextInput from '../components/TextInput';
import PhoneInput from '../components/PhoneInput';
import SearchInput from '../components/SearchInput';
import ImageList from '../components/ImageList';

/**
 * The component property types
 */
interface Props {
    nameHasBlurred?:        boolean;
    emailHasBlurred?:       boolean;
    phoneHasBlurred?:       boolean;
    searchTermsHasBlurred?: boolean;
    nameError?:             string;
    emailError?:            string;
    phoneError?:            string;
    searchTermsError?:      string;
    name:                   string;
    email:                  string;
    phone:                  string;
    searchTerms:            string;
    searchFieldsEnabled:    boolean;
    searchElementRef:       RefObject<HTMLInputElement>;
    showImages?:            boolean;
    onBlur:                 (field: string) => void;
    onChange:               (field: string, value: string) => void;
    onFocusSearch?:         () => void;
    onChangeSearch:         (value: string) => void;
};

/**
 * the GSL (Google search with sales lead) form
 * @return The React component
 */
const GSL: FunctionComponent<Props> = (props: Props): JSX.Element => (
    <div className="gsl form">
        <Columns>
            <Column standard>
                <p className="form-copy has-text-centered" style={{ marginBottom: '1em' }}>
                    Fill in the information below for your free listing scan.
                </p>
                <Columns className="name-email-phone-inputs">

                    <Column width="one-third">
                        <TextInput
                            id="name"
                            field="name"
                            label="Name"
                            required
                            hasBlurred={props.nameHasBlurred}
                            error={props.nameError}
                            value={props.name}
                            placeholder="eg. John Smith"
                            onBlur={props.onBlur}
                            onChange={props.onChange}
                        />
                    </Column>

                    <Column width="one-third">
                        <TextInput
                            id="email"
                            field="email"
                            label="Email"
                            helpText={
                                `We'd like to send you a copy of your scan ' +
                                'and additional information about our products`
                            }
                            required
                            hasBlurred={props.emailHasBlurred}
                            error={props.emailError}
                            value={props.email}
                            placeholder="eg. jsmith@example.com"
                            onBlur={props.onBlur}
                            onChange={props.onChange}
                        />
                    </Column>

                    <Column width="one-third">
                        <PhoneInput
                            id="phone"
                            field="phone"
                            label="Phone"
                            helpText="We ask for this so we can call you & discuss our products"
                            required
                            hasBlurred={props.phoneHasBlurred}
                            error={props.phoneError}
                            value={props.phone}
                            placeholder="eg. 555 555 5555"
                            onBlur={props.onBlur}
                            onChange={props.onChange}
                        />
                    </Column>

                </Columns>
            </Column>
        </Columns>

        <Columns className="gsl-search-column">
            <Column standard>
                <SearchInput
                    label="Business Name & Address"
                    caption={
                        props.searchFieldsEnabled
                            ? 'Start typing to find your business'
                            : 'Please complete the name, email, & phone ' +
                                'above, then start typing to find your business'
                    }
                    helpText={
                        'We will use this information to gather a list from ' +
                        'which you can select your business'
                    }
                    disabled={!props.searchFieldsEnabled}
                    hasBlurred={props.searchTermsHasBlurred}
                    error={props.searchTermsError}
                    value={props.searchTerms}
                    searchElementRef={props.searchElementRef}
                    onBlur={props.onBlur}
                    onFocus={props.onFocusSearch}
                    onChange={props.onChangeSearch}
                />
                {props.showImages ? <ImageList /> : null}
            </Column>
        </Columns>
    </div>
);

// export the component
//
export default GSL;
