// npm imports
//
import React, { FunctionComponent } from 'react';

// local imports
//
import '../../assets/css/ImageList.scss';
import Column from './Column';
import Columns from './Columns';
import errorRate from '../../assets/img/ErrorRate.png';
import listingStatus from '../../assets/img/ListingStatus.png';
import chamberOfCommerce from '../../assets/img/ChamberOfCommerce.png';
import facebook from '../../assets/img/Facebook.png';
import foursquare from '../../assets/img/Foursquare.png';
import googleMaps from '../../assets/img/GoogleMaps.png';

/**
 * The ImageList component
 * @return The React element
 */
const ImageList: FunctionComponent<{}> = () => (
    <div className="ImageList">
        <Columns className="is-vcentered">
            <div className="sample-text">SAMPLE</div>
            <Column width="4">
                <img
                    className="small-example"
                    src={errorRate}
                    alt="Error Rate Example"
                />
            </Column>
            <Column width="4">
                <img
                    className="small-example"
                    src={listingStatus}
                    alt="Listing Status Example"
                />
            </Column>
            <Column className="layered-images small-example">
                <img
                    className="googlemaps"
                    src={googleMaps}
                    alt="Google Maps Example"
                />
                <img
                    className="facebook"
                    src={facebook}
                    alt="Facebook Example"
                />
                <img
                    className="chamberofcommerce"
                    src={chamberOfCommerce}
                    alt="Chamber of Commerce Example"
                />
                <img
                    className="foursquare"
                    src={foursquare}
                    alt="Foursquare Example"
                />
            </Column>
        </Columns>

    </div>
);

// export the component
//
export default ImageList;
