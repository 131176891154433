// npm imports
//
import { stringify } from 'query-string';
import debug from 'debug';

// local imports
//
import { addressToScanParams } from './format-address';
import { Address } from './types/address.d';

// initialize the logging function
//
const log = debug('getlisted:scan-query');

/**
 * Build the query string for the areyoulisted URL
 * @param  {Address} address the address arguments
 * @param  {string} formType the address arguments
 * @param  {string} name     the sales lead name
 * @param  {string} email    the sales lead email
 * @param  {string} phone    the sales lead phone
 * @param  {string} queryParameters the query parameters from the current page
 *                                  URL
 * @return {string}          the query string
 */
export const query = (
    address: Address,
    formType: string = 'gso',
    name?: string,
    email?: string,
    phone?: string,
    queryParameters?: string,
    otherParameters?: { [key: string]: number | string }
): string => {
    log('building query from arguments: %O', {
        address, formType, name, email, phone, queryParameters
    });
    // build the preliminary list of query parameters
    //
    const params = addressToScanParams(address);
    log('formatted address: %O', params);

    // get the form type
    //
    params.formType = formType;

    // is it the GSO (Google Search Only) or BDO (Business Details Only) form?
    // if so, then we need to fill in dummy values for the sales lead data
    //
    if (formType === 'gso' || formType === 'bdo') {
        log('form type = no sales lead');
        // determine the environment
        //
        const dev = process.env.NODE_ENV !== 'production';
        log('environment = development? %s', dev ? 'true' : 'false');

        // build the dummy sales lead name
        //
        params.name = [
            dev ? 'dev-' : '',
            'NALA-RDM-',
            formType === 'gso' ? 'google-search' : 'business-details',
        ].join('');

        // build the dummy email value
        //
        params.email = `${dev ? 'dev-' : ''}no-reply@thenala.com`;

        // build the dummy phone number
        //
        params.phone = `111${dev ? '222' : '111'}5278`;
        log('updated parameters for non-sales-lead form: %O', params);
    } else {
        // otherwise, fill in the sales lead details
        //
        params.name = name;
        params.email = email;
        params.phone = phone;
        log('updated parameters for sales-lead form: %O', params);
    }

    // build the parameters
    //
    let result = stringify({ ...params, ...otherParameters });
    log('converted parameters to URL string: %s', result);

    // do we have query parameters? if so, append them to the result (stripping
    // any leading question mark)
    //
    if (queryParameters) {
        result = `${result}&${queryParameters.replace(/^[?]/, '')}`;
    }
    log('appended query parameters: %s', result);

    // return the parameters as a URL string
    //
    return result;
};
