// npm imports
//
import * as React from 'react';
import { ReactNode, FunctionComponent } from 'react';
import classnames from 'classnames';
import debug from 'debug';

// initialize the log function
//
const log = debug('getlisted:Column');

// the component property types
//
interface Props {
    standard?: boolean;
    width?:  string;
    offset?: string;
    className?: string;
    children?: ReactNode;
};

/**
 * The Column component
 * @return The React element
 */
const Column: FunctionComponent<Props> = (props: Props): JSX.Element => {
    // determine the column width & height
    //
    let colWidth = props.standard ? '6' : props.width;
    let colOffset = props.standard ? '3' : props.offset;

    log('rendering column: width = %s, offset = %s', colWidth, colOffset);

    // render & return the component
    //
    return (
        <div
            className={classnames(
                'column',
                props.className,
                {
                    [`is-${colWidth}`]:         colWidth,
                    [`is-offset-${colOffset}`]: colOffset,
                }
            )}
        >
            {props.children}
        </div>
    );
};

// export the component
//
export default Column;
