// local imports
//
import { Address, ScanParameters } from './types/address.d';

/**
 * Format an address
 * @param  {Address[]} address The array of address components
 * @return {string}            The formatted address
 */
export const format = (address: Address): string =>
    // build an array of address components
    //
    [address.address1, address.address2, address.city, `${address.state} ${address.zip}`.trim()]
        // filter out any empty/blank/undefined values
        //
        .filter((comp) => !!comp)

        // join comma-separated
        //
        .join(', ')

        // replace any instances of multiple (2 or more) whitespace characters
        // with a single space
        //
        .replace(/\s\s{1,}/g, ' ')

        // trim any leading/trailing whitespace
        //
        .trim();

/**
 * Convert an address to the scan tool parameters
 * @param  {Address} address The address values
 * @return {ScanParameters}   The URL parameters
 */
export const addressToScanParams = (address: Address): ScanParameters => ({
    btype:    'single-location',
    phone:    address.phone,
    bname:    address.businessName,
    baddr:    [address.address1, address.address2].join(' ').trim(),
    bcity:    address.city,
    bcountry: address.country,
    bstate:   address.state,
    bzip:     address.zip,
    bphone:   address.businessPhone,
    email:    address.email || process.env.REACT_APP_DEFAULT_EMAIL,
    name:     address.name,
});
