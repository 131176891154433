// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import debug from 'debug';
import Cleave from 'cleave.js/react';

// local imports
//
import '../../assets/css/TextInput.scss';
import Caption from './Caption';

// initialize the log function
//
const log = debug('getlisted:ZipCodeInput');

/**
 * The event object type
 */
interface Event {
    target: { value: string };
};

/**
 * The Props type declaration
 */
interface Props {
    id?:          string;
    country:      'us' | 'ca';
    field:        string;
    label?:       string;
    caption?:     string;
    helpText?:    string;
    hasBlurred?:  boolean;
    required?:    boolean;
    error?:       string;
    value:        string;
    placeholder?: string;
    onBlur:       (field: string) => void,
    onChange:     (field: string, value: string) => void,
};

/**
 * The input (Cleave) element properties declaration
 */
interface InputElementProps {
    id?:         string;
    className:   string;
    placeholder: string;
    options:     {
        blocks:      number[];
        numericOnly: boolean;
        uppercase:   boolean;
    };
    value:       string;
    onBlur:      (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange:    (event: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * The ZipCodeInput component
 *
 * Note that, despite the name, this handles both US zip codes and Canadian
 * postal codes
 *
 * @returns The React component
 */
const ZipCodeInput: FunctionComponent<Props> = (props: Props): JSX.Element => {
    log('rendering ZipCodeInput');

    // build the input element properties for the US Zip Code input element
    //
    // The Cleave component (which I'm using for formatted input) cannot handle
    // changes to properties after rendering. See
    // `https://github.com/nosir/cleave.js/issues/197`. So, in order to
    // correctly switch between US & Canadian Zip/Postal codes, I have to render
    // two distinct Cleave elements & hide the one that's not currently in use
    // (based on the `country` prop).
    //
    const zipCodeInputProps: InputElementProps = {
        className:   classnames('input', { 'is-danger': props.hasBlurred && props.error }),
        placeholder: props.placeholder || '',
        options:     { blocks: [5], numericOnly: true, uppercase: true },
        value:       props.value,
        onBlur:      () => props.onBlur(props.field),
        onChange:    (ev: Event) => props.onChange(props.field, ev.target.value),
    }

    // build the input element properties for the Canadian Postal Code input
    // element
    //
    const postalCodeInputProps: InputElementProps = {
        className:   classnames('input', { 'is-danger': props.hasBlurred && props.error }),
        placeholder: props.placeholder || '',
        options:     { blocks: [3, 3], numericOnly: false, uppercase: true },
        value:       props.value,
        onBlur:      () => props.onBlur(props.field),
        onChange:    (ev: Event) => props.onChange(props.field, ev.target.value),
    }

    // do we have an ID value? if so, add it to the element properties
    //
    if (props.id) {
        zipCodeInputProps.id = `${props.id}-us-input`;
        postalCodeInputProps.id = `${props.id}-ca-input`;
    }
    log('built zip input element props: %O', zipCodeInputProps);

    // render a label element if a label was provided
    //
    const labelElement = props.label ? <label className="label">{props.label}</label> : null;

    // render & return the component
    //
    return (
        <div className="field TextInput">
            {labelElement}
            {props.required ? <span className="required-asterisk">*</span> : null}
            {props.helpText
                ? (
                    <span
                        id={`${props.field}-help-icon`}
                        className="jam jam-help"
                        data-for="react-tooltip"
                        data-tip="custom show"
                        data-event="click focus"
                    />
                )
                : null
            }
            {props.helpText
                ? (
                    <ReactTooltip
                        id="react-tooltip"
                        type="light"
                        globalEventOff="click"
                    >
                        {props.helpText}
                    </ReactTooltip>
                )
                : null
            }

            <div
                className={classnames(
                    'cleave-control',
                    'control',
                    { 'is-hidden': props.country !== 'us'}
                )}
            >
                <Cleave {...zipCodeInputProps} />
            </div>

            <div
                className={classnames(
                    'cleave-control',
                    'control',
                    { 'is-hidden': props.country === 'us'}
                )}
            >
                <Cleave {...postalCodeInputProps} />
            </div>

            <Caption
                id={props.id}
                caption={props.caption}
                hasBlurred={props.hasBlurred}
                error={props.error}
            />
        </div>
    );
};

// export the component
//
export default ZipCodeInput;
