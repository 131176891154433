// npm imports
//
import debug from 'debug';

// local imports
//
import { Address } from './types/address.d';

// initialize the log function
//
const log = debug('getlisted:query');

/**
 * The type of the fetch function
 */
type FetchFunction = typeof window.fetch;

/**
 * Query the API for Google search results
 * @param queryString The query string
 * @param fetchStub A mock fetch function **FOR TESTING ONLY**
 * @returns A promise to return a list of addresses
 */
export const query = (queryString: string, fetchStub?: FetchFunction): Promise<Address[]> => {
    // build the URL
    //
    const url = `/api/query?q=${encodeURI(queryString)}`;
    log('executing query against %s', url);

    // execute the request (using the mock fetch function if it exists)
    //
    return (fetchStub || window.fetch)(url)

        // ...then extract the JSON result
        //
        .then((res) => res.json())

        // ...then iterate over the results & make sure the country value is
        // lower case
        //
        .then((res: Address[]) => {
            log('got results %O', res);

            // convert country values to lower case
            //
            log('ensuring that country value is lower case');
            res.forEach((result) => {
                if (result.country) {
                    // eslint-disable-next-line no-param-reassign
                    result.country = `${result.country}`.toLowerCase();
                }
            });

            // return the results
            //
            log('returning results');
            return res;
        });
};