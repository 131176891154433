// npm imports
//
import * as React from 'react';
import { RefObject } from 'react';
import { FunctionComponent } from 'react';

// local imports
//
import '../../assets/css/SearchInput.scss';
import TextInput from '../components/TextInput';

/**
 * The component property types
 */
interface Props {
    label?:       string;
    caption?:     string;
    helpText?:    string;
    disabled?:    boolean;
    hasBlurred?:  boolean;
    error?:       string;
    value:        string;
    searchElementRef?: RefObject<HTMLInputElement>;
    onBlur:       (field: string) => void;
    onFocus?:     (event: React.FocusEvent) => void;
    onChange:     (value: string) => void;
};

/**
 * the SearchInput form element
 * @return The React component
 */
const SearchInput: FunctionComponent<Props> = (props: Props): JSX.Element => (
    <div className="SearchInput">
        <TextInput
            id="SearchInput"
            disabled={props.disabled}
            field="searchTerms"
            label={props.label}
            caption={props.caption}
            helpText={props.helpText}
            hasBlurred={props.hasBlurred}
            error={props.error}
            value={props.value}
            placeholder="eg. Business, LLC. 1234 Main St, Los Angeles"
            inputRef={props.searchElementRef}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onChange={(field, value) => props.onChange(value)}
        />
    </div>
);

// export the component
//
export default SearchInput;
