// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';

// local imports
//
import TextInput from '../../components/TextInput';

/**
 * The component property types
 */
interface Props {
    required?: boolean;
    hasBlurred?: boolean;
    error?:      string;
    helpText?:   string;
    value:       string;
    onBlur:      (field: string) => void;
    onChange:    (field: string, value: string) => void;
};

/**
 * The BusinessAddress first line component
 */
const BusinessAddress1: FunctionComponent<Props> = (props: Props): JSX.Element => (
    <TextInput
        required
        id="address1"
        field="address1"
        label="Business Address"
        helpText={props.helpText}
        hasBlurred={props.hasBlurred}
        error={props.error}
        value={props.value}
        placeholder="eg. 1234 Main St"
        onBlur={props.onBlur}
        onChange={props.onChange}
    />
);

// export the component
//
export default BusinessAddress1;
