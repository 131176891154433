// npm imports
//
import * as React from 'react';
import { ReactNode, FunctionComponent } from 'react';
import classnames from 'classnames';

/**
 * The component property types
 */
interface Props {
    className?: string;
    children?: ReactNode;
};

/**
 * The Columns component
 * @return The React element
 */
const Columns: FunctionComponent<Props> = ({ className, children }) =>
    <div className={classnames('columns', className)}>{children}</div>;

// export the component
//
export default Columns;
