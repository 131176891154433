// npm imports
//
import * as React from 'react';
import { FunctionComponent, RefObject } from 'react';

// local imports
//
import '../../assets/css/GSO.scss';
import Columns from '../components/Columns';
import Column from '../components/Column';
import SearchInput from '../components/SearchInput';
import ImageList from '../components/ImageList';

/**
 * The component property types
 */
interface Props {
    disabled?:        boolean;
    hasBlurred?:      boolean;
    error?:           string;
    value:            string;
    showImages?:      boolean;
    // permit an undefined type on this prop **FOR TESTING ONLY**
    searchElementRef?: RefObject<HTMLInputElement>;
    onBlur:           (field: string) => void;
    onFocusSearch?:   () => void;
    onChange:         (value: string) => void;
};

export type GSOComponent = FunctionComponent<Props>;

/**
 * The GSO (Google search only) form
 * @return The React component
 */
const GSO: GSOComponent = (props: Props): JSX.Element => (
    <div className="gso form">
        <Columns>
            <Column standard>
                <p className="form-copy">
                    Enter your business information below to run your free listing scan.
                </p>
                <SearchInput
                    label="Business Name & Address"
                    caption="Start typing to find your business"
                    helpText={
                        'We will use this information to gather a list from ' +
                        'which you can select your business'
                    }
                    disabled={props.disabled}
                    hasBlurred={props.hasBlurred}
                    error={props.error}
                    value={props.value}
                    searchElementRef={props.searchElementRef}
                    onBlur={props.onBlur}
                    onFocus={props.onFocusSearch}
                    onChange={props.onChange}
                />
                {props.showImages ? <ImageList /> : null}
            </Column>
        </Columns>
    </div>
);

// export the component
//
export default GSO;
