// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';

// local imports
//
import '../../assets/css/SearchResults.scss';
import Columns from './Columns';
import Column from './Column';
import SearchResultItem from './SearchResultItem';

// type imports
//
import { Address } from '../types/address.d';

/**
 * The component property types
 */
interface Props {
    maxHeight?:    number;
    results:       Address[];
    onClickResult: (result: Address) => void;
    onClickCancel: () => void;
};

/**
 * the SearchResults (Business Details Only) form
 * @return The React component
 */
const SearchResults: FunctionComponent<Props> = (props: Props): JSX.Element => {
    const styles = props.maxHeight ? { maxHeight: `${props.maxHeight}px` } : {};
    return (
        <Columns>
            <Column standard>
                <div className="search-results">
                    <p className="select-message has-text-centered">
                        Please select your business from the list below&hellip;
                    </p>
                    <ul className="has-text-left" style={styles}>
                        {props.results.map((res) => (
                            <SearchResultItem
                                key={res.placeId}
                                result={res}
                                onClick={props.onClickResult}
                            />
                        ))}
                    </ul>
                    <p className="link-message has-text-centered">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={props.onClickCancel}>
                            <span className="browser">
                                Not seeing your business? Click here to enter your details.
                            </span>
                            <span className="mobile">
                                Not seeing your business?
                            </span>
                        </a>
                    </p>
                </div>
            </Column>
        </Columns>
    );
};

// export the component
//
export default SearchResults;
