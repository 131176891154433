// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import debug from 'debug';

// initialize the log function
//
const log = debug('getlisted:Caption');

/**
 * The component properties
 */
interface Props {
    id?:          string;
    caption?:     string;
    hasBlurred?:  boolean;
    error?:       string;
};

/**
 * The caption element properties
 */
interface ElementProps extends Props {
    className: string;
}

/**
 * Render the Caption component
 * @type The React element
 */
const Caption: FunctionComponent<Props> = (props: Props): JSX.Element | null => {
    log('rendering Caption component');
    // build the basic caption properties
    //
    const captionProps: ElementProps = {
        className: classnames('help', props.hasBlurred && props.error ? 'is-danger' : 'caption'),
    };

    // do we have an ID? if so, add it to the props
    //
    if (props.id) {
        captionProps.id = `${props.id}-caption`;
    }
    log('caption props = %O', captionProps);

    // declare a variable to hold the element
    //
    let element = null;

    // do we have a caption? render it!
    //
    if (props.caption) {
        element = <p {...captionProps}>{props.caption}</p>;
    }

    // do we have an error and has the user blurred the element? if so, render
    // the error message!
    //
    if (props.hasBlurred && props.error) {
        element = <p {...captionProps}>{props.error}</p>;
    }

    // return the element
    //
    return element;
};

// export the component
//
export default Caption;
