// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';
import classnames from 'classnames';

// local imports
//
import '../../assets/css/HeaderBar.scss';
import logo from '../../assets/img/thenala-logo-color-reverse.png'
import Columns from './Columns';
import Column from './Column';

/**
 * The HeaderBar component
 */
const HeaderBar: FunctionComponent<{}> = (): JSX.Element => (
    <div className="HeaderBar">
        <Columns className="is-mobile is-tablet is-vcentered">

            <Column
                className={classnames(
                    'is-3-desktop',
                    'is-offset-3-desktop',
                    'is-3-tablet',
                    'is-offset-3-tablet',
                    'is-half-mobile',
                    'logo',
                )}
            >
                <img src={logo} alt="the Nala logo" />
            </Column>

            <Column
                className={classnames(
                    'is-3-desktop',
                    'is-3-tablet',
                    'is-half-mobile',
                    'phone-number',
                )}
            >
                <a href="tel:866-767-3238">(866) 767-3238</a>
            </Column>

        </Columns>
    </div>
);

// export the component
//
export default HeaderBar;
