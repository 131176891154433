// npm imports
//
import React, { FunctionComponent } from 'react';

// local imports
//
import '../../assets/css/Spinner.scss';
import snake from '../../assets/img/snake.png'

/**
 * The Spinner component
 * @return The React element
 */
const Spinner: FunctionComponent<{}> = () => (
    <div className="Spinner has-text-centered">
        <img src={snake} alt="Working..." />
    </div>
);

// export the component
//
export default Spinner;
