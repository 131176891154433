export const formatTime  = (seconds: number) => {
    const min = Math.floor(seconds / 60);
    const sec = Math.round( seconds % 60);

    let result = [`${sec} second${sec !== 1 ? 's' : ''}`];
    if (min) {
        result.unshift(`${min} minute${min > 1 ? 's' : ''}`);
    }
    if (min === 1 && sec === 0) {
        return '60 seconds';
    }

    return result.join(', ');
}