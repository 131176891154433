// npm imports
//
import * as React from 'react';
import { FunctionComponent, RefObject } from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import debug from 'debug';

// local imports
//
import '../../assets/css/TextInput.scss';
import Caption from './Caption';

// initialize the log function
//
const log = debug('getlisted:TextInput');

/**
 * The component properties type declaration
 */
interface Props {
    id?:          string;
    field:        string;
    label?:       string;
    caption?:     string;
    helpText?:    string;
    required?:    boolean;
    hasBlurred?:  boolean;
    disabled?:    boolean;
    error?:       string;
    value:        string;
    placeholder?: string;
    inputRef?:    RefObject<HTMLInputElement>;
    onBlur:       (field: string) => void;
    onFocus?:     (event: React.FocusEvent) => void;
    onChange:     (field: string, value: string) => void;
};

/**
 * The input element properties type
 */
interface InputElementProps {
    id?: string;
    type: 'text';
    className: string;
    disabled:    boolean;
    placeholder: string;
    autoComplete?: 'on' | 'off';
    ref?:    RefObject<HTMLInputElement>;
    value: string;
    onBlur:       (event: React.FocusEvent) => void,
    onFocus?:     (event: React.FocusEvent) => void;
    onChange?:    (event: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * The TextInput component
 * @return The React element
 */
const TextInput: FunctionComponent<Props> = (props: Props): JSX.Element => {
    log('rendering TextInput component');
    // build the input element properties
    //
    const inputProps: InputElementProps = {
        type:        'text',
        className:   classnames('input', { 'is-danger': props.hasBlurred && props.error }),
        disabled:    !!props.disabled,
        placeholder: props.placeholder || '',
        value:       props.value,
        onBlur:      (event) => props.onBlur(props.field),
        onChange:    (event) => props.onChange(props.field, event.target.value),
    };

    // do we have an onFocus handler? if so, add it to the properties
    //
    if (props.onFocus) {
        inputProps.onFocus = props.onFocus;
    }

    // do we have an input reference? if so, add it to the properties
    //
    if (props.inputRef) {
        inputProps.ref = props.inputRef;
        inputProps.autoComplete = 'off';
    }

    // do we have an ID value? if so, add it to the input element with the
    // suffix '-input' (this is an aid to testing, so I can find the element)
    //
    if (props.id) {
        inputProps.id = `${props.id}-input`;
    }

    // render a label element if a label was provided
    //
    const labelElement = props.label ? <label className="label">{props.label}</label> : null;

    log('input element props = %O', inputProps);
    // render & return the element
    //
    return (
        <div className="field TextInput">
            {labelElement}
            {props.required ? <span className="required-asterisk">*</span> : null}
            {props.helpText
                ? (
                    <span
                        id={`${props.field}-help-icon`}
                        className="jam jam-help"
                        data-for="react-tooltip"
                        data-tip="custom show"
                        data-event="click focus"
                    />
                )
                : null
            }
            {props.helpText
                ? (
                    <ReactTooltip
                        id="react-tooltip"
                        type="light"
                        globalEventOff="click"
                    >
                        {props.helpText}
                    </ReactTooltip>
                )
                : null
            }
            <div className="control"><input {...inputProps} /></div>
            <Caption
                id={props.id}
                caption={props.caption}
                hasBlurred={props.hasBlurred}
                error={props.error}
            />
        </div>
    );
};

// export the component
//
export default TextInput;
