// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import debug from 'debug';

// local imports
//
import '../../assets/css/TextInput.scss';
import { CountryType } from '../../App.d';
import '../types/SelectOptions.d.ts';

// initialize the log function
//
const log = debug('getlisted:CountrySelect');

/**
 * The event object type
 */
interface Event {
    target: { value: string };
};

/**
 * The component property types
 */
interface Props {
    id?:          string;
    field:        string;
    label?:       string;
    caption?:     string;
    helpText?:    string;
    value:        CountryType;
    onChange:     (field: string, value: string) => void;
};

/**
 * The caption element properties
 */
interface CaptionProps {
    id?:       string
    className: string;
};

/**
 * The select element properties
 */
interface SelectElementProps extends CaptionProps {
    value:    string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

/**
 * The Country select element
 * @returns The React element
 */
const CountrySelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
    log('rendering CountrySelect component');
    // build the props for the select element
    //
    const selectProps: SelectElementProps = {
        className: 'select',
        value:     props.value,
        onChange:  (ev: Event) => props.onChange(props.field, ev.target.value),
    };

    // build the props for the error element
    //
    const captionProps: CaptionProps = {
        className: 'help has-text-white',
    };

    // do we have an ID value? if so, add it to the select element
    //
    if (props.id) {
        selectProps.id = `${props.id}-select`;
        captionProps.id = `${props.id}-caption`;
    }

    // render a label element if a label was provided
    //
    const labelElement = props.label ? <label className="label">{props.label}</label> : null;

    // declare a variable to hold the caption element
    //
    let captionElement = null;

    // do we have a caption? if so, render the element with the message
    //
    if (props.caption) {
        captionElement = <p {...captionProps}>{props.caption}</p>;
    }

    log('built select element props: %O', selectProps);
    log('built caption element props: %O', captionProps);

    // render & return the component
    //
    return (
        <div className="field TextInput">
            {labelElement}
            {props.helpText
                ? (
                    <span
                        id={`${props.field}-help-icon`}
                        className="jam jam-help"
                        data-for="react-tooltip"
                        data-tip="custom show"
                        data-event="click focus"
                    />
                )
                : null
            }
            {props.helpText
                ? (
                    <ReactTooltip
                        id="react-tooltip"
                        type="light"
                        globalEventOff="click"
                    >
                        {props.helpText}
                    </ReactTooltip>
                )
                : null
            }
            <div className="control">
                <div className="select is-fullwidth">
                    <select {...selectProps}>
                        <option value='us'>USA</option>
                        <option value='ca'>Canada</option>
                    </select>
                </div>
            </div>
            {captionElement}
        </div>
    );
};

// export the component
//
export default CountrySelect;
