// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';
import classname from 'classnames';

// local imports
//
import '../../assets/css/AddressDisplay.scss';
import { format as formatPhone } from '../format-phone';

// type imports
//
import { Address } from '../types/address.d';

// the component property type
//
interface Props {
    className?: string;
    address:    Address;
    left?:      boolean;
};

/**
 * The AddressDisplay component
 * @return The React element
 */
const AddressDisplay: FunctionComponent<Props> = (props: Props): JSX.Element => (
    <div className={classname('AddressDisplay', { 'has-text-left': props.left })}>
        {props.address.businessName || props.address.businessPhone ? (
            <span className={classname('address-line', 'name-phone', props.className)}>
                {props.address.businessName
                    ? (
                        <span className={classname('name', props.className)}>
                            {props.address.businessName}
                        </span>
                    )
                    : null
                }
                {props.address.businessName && props.address.businessPhone
                    ? <span>&nbsp;</span>
                    : null
                }
                {props.address.businessPhone
                    ? (
                        <span className={classname('phone', props.className)}>
                            {formatPhone(props.address.businessPhone)}
                        </span>
                    )
                    : null
                }
            </span>
        ) : null}
        <span className={classname('address-line', 'address1', props.className)}>
            {props.address.address1}
        </span>
        {props.address.address2
            ? (
                <span className={classname('address-line', 'address2', props.className)}>
                    {props.address.address2}
                </span>
            )
            : null
        }
        <span className={classname('address-line', 'city-state-zip', props.className)}>
            <span className='city'>{props.address.city},</span>
            {' '}
            <span className='state'>{props.address.state}</span>
            {' '}
            <span className='zip'>{props.address.zip}</span>
        </span>
    </div>
);

// export the component
//
export default AddressDisplay;
