// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';

// local imports
//
import TextInput from '../../components/TextInput';

/**
 * The component property types
 */
interface Props {
    required?:   boolean;
    hasBlurred?: boolean;
    error?:      string;
    helpText?:   string;
    value:       string;
    onBlur:      (field: string) => void;
    onChange:    (field: string, value: string) => void;
};

/**
 * The BusinessName component
 * @type {React.element}
 */
const BusinessName: FunctionComponent<Props> = (props: Props): JSX.Element => (
    <TextInput
        required
        id="businessName"
        field="businessName"
        label="Business Name"
        caption="Please enter your business name exactly as it should appear online"
        helpText={props.helpText}
        hasBlurred={props.hasBlurred}
        error={props.error}
        value={props.value}
        placeholder="eg. Acme, Inc."
        onBlur={props.onBlur}
        onChange={props.onChange}
    />
);

// export the component
//
export default BusinessName;
