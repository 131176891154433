// npm imports
//
import * as React from 'react';
import { FunctionComponent } from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import ReactTooltip from 'react-tooltip';

// local imports
//
import '../../assets/css/ConfirmationModal.scss';
import AddressDisplay from './AddressDisplay';
import { formatList } from '../format-list';

// type imports
//
import { Address } from '../types/address.d';

// the component property type
//
interface Props {
    address:   Address;
    formType?: string;
    onClickOK: (address: Address) => void;
    onClickCancel: (address?: Address) => void;
};

const addressScheme = yup.object({
    businessPhone: yup.string().required('business phone number'),
    address1:      yup.string().required('address'),
    city:          yup.string().required('city name'),
    state:         yup.string().required('state'),
    zip:           yup.string().required('zip'),
});

const getErrors = (address: Address): string[] | null => {
    try {
        addressScheme.validateSync(address, { abortEarly: false });
        return null;
    } catch(err) {
        let errors = [];
        if (err.inner && err.inner.length > 0) {
            errors = err.inner.map((error: Error) => (error as Error).message);
        } else {
            errors = [err.message];
        }
        return errors;
    }
};

/**
 * The ConfirmationModal component
 * @return The React element
 */
const ConfirmationModal: FunctionComponent<Props> = (props: Props): JSX.Element => {
    const errors = getErrors(props.address);
    return (
        <ReactModal
            isOpen
            className="confirmation-modal"
            overlayClassName="modal-overlay"
            contentLabel="confirmation modal"
        >

            <div className="modal-header">
                Confirm Your Business Details
            </div>

            <div className="modal-body">
                <div className="confirmation-modal-message">

                    {!errors ? null : (
                        <article className="message is-warning">
                            <div className="message-body">
                                <p>
                                    <strong>
                                        Your information online is incomplete!
                                        {' '}
                                        <span
                                            id="confirmation-modal-help-icon"
                                            className="jam jam-help"
                                            data-for="react-tooltip"
                                            data-tip="custom show"
                                            data-event="click focus"
                                        />
                                    </strong>
                                </p>
                                <ReactTooltip
                                    id="react-tooltip"
                                    type="dark"
                                    globalEventOff="click"
                                >
                                    We searched Google for your business and
                                    found that Google is missing your
                                    {' '}
                                    {formatList(errors)}.
                                </ReactTooltip>

                                <p>
                                    You could be losing out on business as
                                    potential customers can't find you.
                                </p>
                                <p>
                                    Please add the missing information to finish
                                    the scan.
                                </p>
                            </div>
                        </article>
                    )}

                    <AddressDisplay address={props.address} />

                </div>
            </div>

            <div className="browser modal-footer">

                <div className="is-pulled-left">
                    <Link to={`/${props.formType}`} className="back-link">&lt;&nbsp;Back</Link>
                </div>

                <div className="is-pulled-right">
                    {!errors
                        ? (
                            <button
                                className="button is-primary continue-button"
                                onClick={() => props.onClickOK(props.address)}
                            >
                                <span className="mobile">Yes, My Info is Correct!</span>
                                <span className="browser">Continue</span>
                            </button>
                        )
                        : null
                    }
                    <button
                        className="button is-primary correction-button"
                        onClick={() => { props.onClickCancel(props.address); }}
                    >
                        <span className="mobile">
                            {errors ? 'Continue' : 'No, Let Me Correct my Business Info'}
                        </span>
                        <span className="browser">
                            {errors ? 'Continue' : 'Make Corrections'}
                        </span>
                    </button>
                </div>

            </div>

            <div className="mobile modal-footer has-text-centered">

                {!errors
                    ? (
                        <button
                            className="button is-primary continue-button"
                            onClick={() => props.onClickOK(props.address)}
                        >
                            <span className="mobile">Yes, My Info is Correct!</span>
                            <span className="browser">Continue</span>
                        </button>
                    )
                    : null
                }

                <button
                    className="button is-primary correction-button"
                    onClick={() => { props.onClickCancel(props.address); }}
                >
                    <span className="mobile">
                        {errors ? 'Continue' : 'No, Let Me Correct my Business Info'}
                    </span>
                    <span className="browser">
                        {errors ? 'Continue' : 'Make Corrections'}
                    </span>
                </button>

                <Link to={`/${props.formType}`} className="back-link">&lt;&nbsp;Back</Link>

            </div>

        </ReactModal>
    );
};

// export the component
//
export default ConfirmationModal;
